@primary: var(--mainTheme);
// @primary-hover: fade(color(@primary), 10%);
@actionColor: #ff5501;
@main-color: #1f1f1f;
@content-color: #293d66;
@text-color: #4d5e80;
@main-text-color: #1f2e59;
@label-color: #4d5e8c;
@card-radius: 8px;

.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}
.ant-table-wrapper {
  .ant-pagination {
    padding-right: 12px !important;
  }
}

.ant-layout {
  background-color: #f3f7fa !important;
}
.ant-table-column-sort {
  background: #fafafa !important;
}
.ant-table-cell {
  word-break: break-all;
}
.ant-pro-table-column-setting-overlay {
  .ant-popover-inner-content {
    width: 250px !important;
  }
}
.ant-typography {
  font-size: 12px;
}

.ant-pro-table-column-setting-list-item-title {
  max-width: none !important;
  // min-width: 80px;
}
.ant-table-container {
  .ant-table-cell {
    font-size: 12px;
  }
  .ant-table-pagination .ant-pagination {
    margin-block-end: 0;
  }
}

.ant-form-item-explain-error {
  height: 16px !important;
  line-height: 16px !important;
}
.ant-tabs {
  .ant-tabs-tab {
    color: #4d5e8c;
  }
}

.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.ant-drawer *,
.ant-modal *,
.ant-popover *,
.ant-message *,
.ant-tooltip *,
.ant-select-dropdown *,
.ant-row *,
.ant-layout * {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
.max-tool-tip {
  .tool-tip-content {
    max-height: 200px;
    overflow-y: auto;
    max-width: 300px;
    padding: 0 8px;
    white-space: wrap;
    word-break: break-all;
  }
  .tool-tip-content::-webkit-scrollbar-track {
    border-radius: 12px;
    background: #f7f9fc;
  }
  .tool-tip-content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #f7f9fc;
  }
  .tool-tip-content:-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 12px;
    background-color: #b8c1d9;
  }
  .tool-tip-content:-webkit-scrollbar-thumb:hover {
    background-color: #b8c1d9;
  }
}

.ant-table-filter-dropdown {
  .ant-dropdown-menu-item {
    margin-bottom: 4px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.ant-table-thead {
  > tr {
    > th {
      height: 40px !important; // 设置全局表头的高度
    }
  }
}
